<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <div class="text-md-center">
                <h1> 404 </h1>
                <h2 class="my-3 headline">Page non trouvée</h2>
                <router-link to="/"> Retour à l'accueil </router-link>
            </div>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'NotFound'
};
</script>

<style scoped lang="scss">
h1 {
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #a3adc5;
    text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px,
        rgba(61, 61, 61, 0.3) 3px 3px;
}
</style>